import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageTitle from "../components/page-title"

const Services = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Get in touch" />
      <PageTitle
        title="Get in touch"
        description="Let's talk about your project."
      />
      <section className="section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-sm-12">
              <form
                className="contact__form"
                action="https://invently-prod-uks-func.azurewebsites.net/api/contact"
                method="POST"
              >
                <div className="row">
                  <div className="col-md-6 form-group">
                    <input
                      name="email"
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      required
                    />
                  </div>
                  <div className="col-md-12 form-group">
                    <input
                      name="subject"
                      type="text"
                      className="form-control"
                      placeholder="Subject"
                      required
                    />
                  </div>
                  <div className="col-12 form-group">
                    <textarea
                      name="message"
                      className="form-control"
                      rows="6"
                      placeholder="Message"
                      required
                    ></textarea>
                  </div>
                  <div className="col-12 mt-4">
                    <input
                      name="submit"
                      type="submit"
                      className=" btn btn-hero btn-circled"
                      value="Send Message"
                    />
                  </div>
                </div>
              </form>
            </div>

            <div className="col-lg-5 pl-4 mt-4 mt-lg-0">
              <h4>Location</h4>
              <p className="mb-3">Sheffield, UK</p>
              <h4>Contact Mail</h4>
              <p className="mb-3">hello@invently.co.uk</p>
              <h4>Website</h4>
              <p>www.invently.co.uk</p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Services

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
